import {Datum, Line, ResponsiveLine} from "@nivo/line";
import {useEffect, useState} from "react";
import {getPredictTide} from "../../repository/TideService";
import {Dayjs} from "dayjs";
import {Tide} from "../../repository/types/TideResponse";
import {Spin} from "antd";

export function TideLineGraph(props: {
    tideData: Tide[],
    minTide?: number,
    maxTide?: number
}) {

    const [data, setData] = useState<Datum[]>([])

    useEffect(() => {
        setData([])
            setData(props.tideData
                .filter((tide, index: number, array: Tide[]) => {
                    return new Date(tide.record_time).getMinutes() === 0 && !(index === array.length - 1)
                })
                .map(tide => {
                    const date = new Date(tide.record_time)

                    return {
                        x: date.toTimeString().split(' ')[0],
                        y: tide.tide_level
                    }
                })
            )
    }, [props])

    return (
        <>
            { (data.length !== 0) ?
                <Line
                    width={100}
                    height={80}
                    useMesh={true}
                    pointSize={10}
                    xScale={{
                        type: "point"
                    }}

                    yScale={{
                        type: "linear",
                        min : props.minTide ? props.minTide : "auto",
                        max : props.maxTide ? props.maxTide : "auto"
                    }}
                    margin={{
                        left: 10,
                        top: 10,
                        bottom: 10
                    }}
                    data={[{
                        id: `Tide`,
                        data: data
                    }]}
                    curve={"catmullRom"}
                    animate={false}
                    enablePoints={false}

                /> :  (<Spin spinning={true}/>) }
        </>)
}

