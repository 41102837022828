import {Observatory} from "./types/Observatory";

export const observatories : Observatory[] = [
    new Observatory("SO_0732", "남애항", 37.944, 128.788),
    new Observatory("SO_0733", "강릉항", 37.772, 128.951),
    new Observatory("SO_0734", "궁촌항", 37.327, 129.27),
    new Observatory("SO_0735", "죽변항", 37.054, 129.423),
    new Observatory("SO_0736", "축산항", 36.509, 129.448),
    new Observatory("SO_0737", "강구항", 36.358, 129.391),
    new Observatory("SO_0555", "서망항", 34.366, 126.134),
    new Observatory("DT_0054", "진해", 35.147, 128.643),
    new Observatory("SO_0739", "도장항", 34.367, 127.011),
    new Observatory("SO_0740", "보옥항", 34.129, 126.513),
    new Observatory("SO_0699", "천리포항", 36.803, 126.146),
    new Observatory("SO_0562", "승봉도", 37.169, 126.29),
    new Observatory("SO_0573", "양포항", 35.881, 129.527),
    new Observatory("SO_0581", "강양항", 35.39, 129.344),
    new Observatory("SO_0571", "거제외포", 34.939, 128.718),
    new Observatory("SO_0578", "소매물도", 34.621, 128.548),
    new Observatory("SO_0567", "쉬미항", 34.504, 126.183),
    new Observatory("SO_0576", "화봉리", 34.661, 126.256),
    new Observatory("SO_0564", "국화도", 37.06, 126.56),
    new Observatory("SO_0563", "울도", 37.035, 125.995),
    new Observatory("SO_0706", "청산도", 34.18, 126.856),
    new Observatory("SO_0708", "안도항", 34.479, 127.797),
    new Observatory("SO_0712", "능양항", 34.812, 128.245),
    new Observatory("SO_0701", "홍도항", 34.681, 125.195),
    new Observatory("SO_0702", "진도옥도", 34.35, 126.018),
    new Observatory("SO_0703", "땅끝항", 34.298, 126.531),
    new Observatory("SO_0704", "소안항", 34.15, 126.631),
    new Observatory("DT_0040", "독도", 37.238, 131.867),
    new Observatory("DT_0002", "평택", 36.966, 126.822),
    new Observatory("DT_0003", "영광", 35.426, 126.42),
    new Observatory("DT_0004", "제주", 33.527, 126.543),
    new Observatory("DT_0005", "부산", 35.096, 129.035),
    new Observatory("DT_0006", "묵호", 37.55, 129.116),
    new Observatory("DT_0007", "목포", 34.779, 126.375),
    new Observatory("DT_0008", "안산", 37.192, 126.647),
    new Observatory("DT_0010", "서귀포", 33.24, 126.561),
    new Observatory("DT_0011", "후포", 36.677, 129.453),
    new Observatory("DT_0012", "속초", 38.207, 128.594),
    new Observatory("DT_0013", "울릉도", 37.491, 130.913),
    new Observatory("DT_0016", "여수", 34.747, 127.765),
    new Observatory("DT_0017", "대산", 37.007, 126.352),
    new Observatory("DT_0018", "군산", 35.975, 126.563),
    new Observatory("DT_0021", "추자도", 33.961, 126.3),
    new Observatory("DT_0023", "모슬포", 33.214, 126.251),
    new Observatory("DT_0028", "진도", 34.377, 126.308),
    new Observatory("DT_0032", "강화대교", 37.731, 126.522),
    new Observatory("DT_0036", "대청도", 37.825, 124.718),
    new Observatory("SO_0553", "해운대", 35.16, 129.191),
    new Observatory("SO_0540", "호산항", 37.176, 129.342),
    new Observatory("DT_0020", "울산", 35.501, 129.387),
    new Observatory("DT_0022", "성산포", 33.474, 126.927),
    new Observatory("DT_0024", "장항", 36.006, 126.687),
    new Observatory("DT_0026", "고흥발포", 34.481, 127.342),
    new Observatory("DT_0027", "완도", 34.315, 126.759),
    new Observatory("DT_0029", "거제도", 34.801, 128.699),
    new Observatory("DT_0031", "거문도", 34.028, 127.308),
    new Observatory("DT_0035", "흑산도", 34.684, 125.435),
    new Observatory("DT_0044", "영종대교", 37.545, 126.584),
    new Observatory("DT_0047", "도농탄", 33.158, 126.274),
    new Observatory("DT_0050", "태안", 36.913, 126.238),
    new Observatory("DT_0048", "속초등표", 38.199, 128.613),
    new Observatory("DT_0051", "서천마량", 36.128, 126.495),
    new Observatory("SO_0549", "초도", 34.24, 127.245),
    new Observatory("DT_0049", "광양", 34.903, 127.754),
    new Observatory("DT_0056", "부산항신항", 35.077, 128.786),
    new Observatory("DT_0057", "동해항", 37.494, 129.143),
    new Observatory("SO_0538", "안마도", 35.345, 126.016),
    new Observatory("SO_0539", "강화외포", 37.7, 126.372),
    new Observatory("DT_0058", "경인항", 37.56, 126.601),
    new Observatory("SO_0554", "영종왕산", 37.458, 126.358),
    new Observatory("SO_0326", "미조항", 34.706, 128.048),
    new Observatory("IE_0060", "이어도", 32.122, 125.182),
    new Observatory("DT_0038", "굴업도", 37.194, 125.995),
    new Observatory("DT_0025", "보령", 36.406, 126.486),
    new Observatory("DT_0001", "인천", 37.451, 126.592),
    new Observatory("DT_0052", "인천송도", 37.338, 126.586),
    new Observatory("DT_0014", "통영", 34.827, 128.434),
    new Observatory("DT_0037", "어청도", 36.117, 125.984),
    new Observatory("DT_0046", "쌍정초", 37.556, 130.939),
    new Observatory("DT_0039", "왕돌초", 36.719, 129.732),
    new Observatory("DT_0041", "복사초", 34.098, 126.168),
    new Observatory("DT_0042", "교본초", 34.704, 128.306),
    new Observatory("DT_0043", "영흥도", 37.238, 126.428),
    new Observatory("DT_0061", "삼천포", 34.924, 128.069),
    new Observatory("SO_0537", "벽파진", 34.539, 126.346),
    new Observatory("SO_0536", "덕적도", 37.227, 126.157),
    new Observatory("SO_0547", "말도", 35.855, 126.318),
    new Observatory("SO_0550", "나로도", 34.463, 127.453),
    new Observatory("SO_0705", "마량항", 34.448, 126.821),
    new Observatory("SO_0707", "시산항", 34.394, 127.261),
    new Observatory("SO_0709", "두문포", 34.643, 127.797),
    new Observatory("SO_0710", "봉우항", 34.932, 127.927),
    new Observatory("SO_0711", "창선도", 34.84, 128.019),
    new Observatory("SO_0700", "호도", 36.303, 126.264),
    new Observatory("DT_0059", "백령도", 37.955, 124.736),
    new Observatory("DT_0060", "연평도", 37.657, 125.714),
    new Observatory("SO_0551", "여서도", 33.988, 126.923),
    new Observatory("SO_0552", "고현항", 34.901, 128.622),
    new Observatory("IE_0062", "옹진소청초", 37.423, 124.738),
    new Observatory("IE_0061", "신안가거초", 33.941, 124.592),
    new Observatory("SO_0543", "서거차도", 34.253, 125.917),
    new Observatory("SO_0548", "우이도", 34.62, 125.856),
    new Observatory("SO_0572", "읍천항", 35.69, 129.475),
    new Observatory("SO_0569", "남포항", 34.957, 128.321),
    new Observatory("SO_0570", "광암항", 35.102, 128.498),
    new Observatory("SO_0568", "백야도", 34.624, 127.632),
    new Observatory("SO_0577", "가거도", 34.05, 125.128),
    new Observatory("SO_0566", "송공항", 34.848, 126.225),
    new Observatory("SO_0565", "향화도항", 35.167, 126.359),
    new Observatory("SO_0574", "백사장항", 36.586, 126.315),
    new Observatory("SO_0731", "대진항", 38.501, 128.426),
    new Observatory("SO_1251", "낙월도", 35.2, 126.145),
    new Observatory("SO_1252", "외연도항", 36.225, 126.081),
    new Observatory("SO_0757", "안남리", 34.73, 127.264),
    new Observatory("SO_0755", "원동항", 34.393, 126.648),
    new Observatory("SO_0754", "평호리", 34.448, 126.455),
    new Observatory("SO_1256", "어류정항", 37.643, 126.342),
    new Observatory("DT_0064", "교동대교", 37.789, 126.339),
    new Observatory("SO_1249", "독거도", 34.256, 126.178),
    new Observatory("SO_1250", "평도", 34.245, 127.447),
    new Observatory("SO_1253", "상왕등도", 35.658, 126.11),
    new Observatory("SO_1254", "만재도", 34.21, 125.472),
    new Observatory("SO_1248", "신안옥도", 34.683, 126.064),
    new Observatory("SO_0759", "장문리", 34.873, 128.424),
    new Observatory("DT_0068", "위도", 35.618, 126.301),
    new Observatory("SO_0760", "오산항", 36.888, 129.416),
    new Observatory("SO_0753", "하의도웅곡", 34.608, 126.038),
    new Observatory("SO_0631", "암태도", 34.853, 126.071),
    new Observatory("SO_0752", "검산항", 35.0, 126.107),
    new Observatory("SO_0761", "녹동항", 34.527, 127.134),
    new Observatory("DT_0067", "안흥", 36.674, 126.129),
    new Observatory("DT_0091", "포항", 36.051, 129.376),
    new Observatory("SO_1255", "상태도", 34.435, 125.285),
    new Observatory("SO_0758", "달천도", 34.761, 127.563),
    new Observatory("SO_0756", "사초항", 34.47, 126.761),
    new Observatory("DT_0063", "가덕도", 35.024, 128.81),
    new Observatory("DT_0062", "마산", 35.197, 128.576),
    new Observatory("SO_1257", "강화하리", 37.728, 126.286),
    new Observatory("DT_0092", "여호항", 34.661, 127.469),
    new Observatory("SO_1258", "잠진도", 37.415, 126.415),
    new Observatory("SO_1259", "자월도", 37.243, 126.318),
    new Observatory("SO_1260", "방포항", 36.502, 126.325),
    new Observatory("SO_1261", "무창포항", 36.249, 126.534),
    new Observatory("SO_1262", "격포항", 35.62, 126.463),
    new Observatory("SO_1263", "구시포항", 35.447, 126.425),
    new Observatory("SO_1264", "계마항", 35.39, 126.401),
    new Observatory("SO_1265", "송이도", 35.271, 126.15),
    new Observatory("SO_1266", "남열항", 34.576, 127.48),
    new Observatory("SO_1267", "구룡포항", 35.99, 129.555),
    new Observatory("SO_1268", "궁평항", 37.117, 126.68),
    new Observatory("SO_1270", "삼길포항", 37.004, 126.452),
    new Observatory("SO_1271", "어은돌항", 36.748, 126.129),
    new Observatory("SO_1269", "연도항", 36.081, 126.442),
    new Observatory("SO_1272", "다대포항", 35.054, 128.972),
    new Observatory("SO_1277", "화순항", 33.237, 126.334),
    new Observatory("SO_1274", "거진항", 38.446, 128.456),
    new Observatory("SO_1275", "공현진항", 38.355, 128.513),
    new Observatory("SO_1276", "아야진항", 38.27, 128.557),
    new Observatory("SO_1273", "장호항", 37.288, 129.317),
    new Observatory("SO_1278", "사천진항", 37.837, 128.875)
]
