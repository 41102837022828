import {Tide} from "../../repository/types/TideResponse";
import {useEffect,  useState} from "react";
import {Datum, ResponsiveLine} from "@nivo/line";
import {Dayjs} from "dayjs";
import {getPredictMinMaxTide} from "../../repository/TideService";

interface CustomDatum {
    x: string,
    y: number | null
}

export function TideLineDetail(props: {
    tideData: Tide[],
    dateInfo: Dayjs,
    obsId: string
}) {
    const [data, setData] = useState<Datum[]>([])
    const [minMaxData, setMinMaxData] = useState<Datum[]>([])

    useEffect(() => {
        setData([])

        getPredictMinMaxTide(props.obsId, props.dateInfo.format("YYYYMMDD"))
            .then((res) => {

                const originTide = props.tideData
                    .filter((tide, index: number, array: Tide[]) => {
                        const date = new Date(tide.record_time)
                        return (date.getMinutes() === 0 || date.getMinutes() === 30)
                            && !(index === array.length - 1)
                    })
                    .map(tide => {
                        const date = new Date(tide.record_time)

                        return {
                            x: date.toTimeString().split(' ')[0],
                            y: tide.tide_level
                        }
                    })
                const resultData = res.data.result.data

                let minMaxTide: CustomDatum[] = res.data.result.data.map(tide => {
                    const date = new Date(tide.tph_time)

                    return {
                        x: date.toTimeString().split(' ')[0],
                        y: tide.tph_level
                    }
                })

                let originMinMaxTide = minMaxTide.map(item => item)

                for (let i = 0; i < resultData.length; i++) {
                    let item = resultData[i]
                    const date = new Date(item.tph_time)

                    let before: Date
                    let after: Date

                    if (date.getMinutes() >= 30) {
                        before = new Date(date)
                        before.setMinutes(30)
                        after = new Date(date)
                        after.setMinutes(0)
                        after.setHours(after.getHours() + 1)
                    } else if (date.getMinutes() >= 0) {
                        before = new Date(date)
                        before.setMinutes(0)
                        after = new Date(date)
                        after.setMinutes(30)
                    }

                    const beforeX = before!!.toTimeString().split(' ')[0]
                    const afterX = after!!.toTimeString().split(' ')[0]

                    minMaxTide.push(
                        {
                            x: beforeX,
                            y: null
                        },
                        {
                            x: afterX,
                            y: null
                        },
                    )
                }

                setMinMaxData([...originTide.map(item => {
                    return {
                        x: item.x,
                        y: null
                    }
                }), ...minMaxTide].sort((a, b) => {
                    if (a.x > b.x) return 1
                    else if (a.x === b.x) return 0
                    else return -1
                }))

                setData([...originTide, ...originMinMaxTide]
                    .sort((a, b) => {
                        if (a.x > b.x) return 1
                        else if (a.x === b.x) return 0
                        else return -1
                    })
                )


            })

        setData(props.tideData
            .filter((tide, index: number, array: Tide[]) => {
                const date = new Date(tide.record_time)
                return (date.getMinutes() === 0 || date.getMinutes() === 30)
                    && !(index === array.length - 1)
            })
            .map(tide => {
                const date = new Date(tide.record_time)

                return {
                    x: date.toTimeString().split(' ')[0],
                    y: tide.tide_level
                }
            })
        )
    }, [props])
    return (
        <>
            <h2>{props.dateInfo.format("MM월 DD일")}</h2>
            <ResponsiveLine
                colors={[
                    'rgb(255, 0, 0)',
                    'rgb(97, 205, 187)',
                ]}
                useMesh={true}
                pointSize={15}
                xScale={{
                    type: "point"
                }}

                yScale={{
                    type: "linear",
                    min: "auto",
                    max: "auto"
                }}
                margin={{
                    left: 80,
                    top: 50,
                    bottom: 80,
                    right: 50
                }}
                data={[
                    {
                        id: `MinMaxTide`,
                        data: minMaxData,
                    },
                    {
                        id: `Tide`,
                        data: data
                    },
                ]}
                curve={"monotoneX"}
                axisLeft={{
                    legend: 'cm',
                    legendPosition: 'middle',
                    tickSize: 10,
                    tickRotation: 0,
                }}
                axisBottom={{
                    legendPosition: 'middle',
                    legend: 'time',
                    tickSize: 10,
                    truncateTickAt: 5
                }}
                enablePoints={true}
                enablePointLabel={true}
                layers={['grid', 'markers', 'axes', 'areas', 'crosshair', 'lines', 'points', 'slices', 'mesh', 'legends']}
            />
        </>
    )
}