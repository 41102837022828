import {DatePicker} from "antd";
import {Dispatch, SetStateAction} from "react";

export function SearchDate(
    props: {
        setYear: Dispatch<SetStateAction<number | undefined>>
        setMonth: Dispatch<SetStateAction<number | undefined>>
    }
){
    return(
        <DatePicker
            placeholder={"조회할 달을 선택해 주세요."}
            style = {{width : '30%'}}
            picker={"month"}
            format = "YYYY-MM"
            onChange={(item)=>{
                let date = item.year().toString()
                if((item.month()+1) < 10) date += '0'
                date += (item.month()+1).toString()
                props.setYear(item.year())
                props.setMonth(item.month()+1)
            }}


        />
    )
}