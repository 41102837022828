import {Select} from "antd";
import {observatories} from "../../constant/observatories";
import {Dispatch, SetStateAction} from "react";

export function SearchObservatory(props: {
    setObsId: Dispatch<SetStateAction<string | undefined>>
}) {
    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        || (option?.value ?? '').toLowerCase().includes(input.toLowerCase())

    return (
        <Select
            placeholder={"조회할 지역을 선택해 주세요."}
            showSearch
            style={{width: '30%'}}
            onSelect={(item) => {
                props.setObsId(item)

            }}
            filterOption={filterOption}
            options={observatories.map((obs) => {
                return {
                    value: obs.id,
                    label: obs.name
                }
            })}
        />
    )
}
