import React, {useEffect, useState} from 'react';
import './App.css';
import {SearchObservatory} from "./components/search/SearchObservatory";
import {SearchDate} from "./components/search/SearchDate";
import {TideCalendar} from "./components/view/TideCalendar";

function App() {
    const [obsId, setObsId] = useState<string>()
    const [year, setYear] = useState<number>()
    const [month, setMonth] = useState<number>()

    return (
        <div className="App" style={{width: '100%', height: '100%'}}>
            <SearchObservatory setObsId={setObsId}/>
            <SearchDate setYear={setYear} setMonth={setMonth}/>

            <a>{obsId}</a>
            {(year && month && obsId) &&
                <TideCalendar obsId={obsId} year={year} month={month}/>
            }

        </div>
    );
}

export default App;
