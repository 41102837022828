import {tideAxios} from "../library/axios";
import {MinMaxTide, Tide, TideResponse} from "./types/TideResponse";

export function getPredictTide(
    obsCode: string,
    date: string
){
    return tideAxios.get<TideResponse<Tide>>("/tideObsPre/search.do", {
        params : {
            ObsCode: obsCode,
            Date: date,
            ResultType : "json"
        }
    })
}

export function getPredictMinMaxTide(
    obsCode: string,
    date: string
){
    return tideAxios.get<TideResponse<MinMaxTide>>("/tideObsPreTab/search.do", {
        params : {
            ObsCode: obsCode,
            Date: date,
            ResultType : "json"
        }
    })
}

