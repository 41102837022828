import {Button, Calendar, Modal} from "antd";
import dayjs, {Dayjs} from "dayjs";
import {useEffect, useRef, useState} from "react";
import {TideLineGraph} from "./TideLineGraph";
import {getPredictTide} from "../../repository/TideService";
import {Tide} from "../../repository/types/TideResponse";
import {TideLineDetail} from "./TideLineDetail";
import {useReactToPrint} from "react-to-print";

export function TideCalendar(props: {
    obsId: string
    year: number,
    month: number,
}) {
    const [tideData, setTideData] = useState<Map<number, Tide[]>>(new Map())
    const [minTide, setMinTide] = useState<number>()
    const [maxTide, setMaxTide] = useState<number>()
    const [selectedDate, setSelectedDate] = useState<Dayjs>()
    const dailyRef = useRef(null);
    const calendarRef = useRef(null);
    const handlePrint = useReactToPrint({
        documentTitle : '조위표',
        onAfterPrint: ()=>{
            setSelectedDate(undefined)
        },
        removeAfterPrint: true
    })
    const handleCalendarPrint = useReactToPrint({
        documentTitle : '월간 조위표',
        onAfterPrint: ()=>{
            setSelectedDate(undefined)
        },
        removeAfterPrint: true
    })
    useEffect(() => {
        let firstDay = dayjs(props.year + "-" + props.month)
        let daysInMonth = firstDay.daysInMonth()
        setTideData(new Map())
        setSelectedDate(undefined)

        let outerMin : number
        let outerMax : number

        for (let i = 0; i < daysInMonth; i++) {
            getPredictTide(
                props.obsId,
                firstDay.add(i, "day").format('YYYYMMDD')
            ).then(res => {
                const tideLevels = res.data.result.data.map(item => item.tide_level)
                const min = Math.min(...tideLevels)
                const max = Math.max(...tideLevels)

                if (outerMin == null || outerMin > min) {
                    outerMin = min
                }
                if (outerMax == null || outerMax < max) {
                    outerMax = max
                }

                setMinTide(outerMin)
                setMaxTide(outerMax)

                tideData.set(i + 1, res.data.result.data)
                setTideData(tideData)
            })
        }


    }, [props])


    function isValidDate(currentDate: Dayjs) {
        return currentDate.month() === props.month - 1
    }

    return (
        <>

            <hr/>
            <h4>최저 수위 : {minTide}</h4>
            <h4>최고 수위 : {maxTide}</h4>
            <hr/>
            <Button type={"primary"} onClick={()=>{
                handleCalendarPrint(null, ()=>calendarRef.current)
            } }>Print</Button>
            <div ref ={calendarRef}>

            <Calendar
                mode={"month"}
                headerRender={({value}) => {
                    return <h2>{value.month() + 1} 월 - {props.obsId}</h2>
                }}
                cellRender={(date) => {
                    return (isValidDate(date) && tideData.has(date.date()))
                        ? <TideLineGraph tideData={tideData.get(date.date())!!} minTide={minTide}
                                         maxTide={maxTide}/> : <></>
                }}
                value={dayjs(props.year + "-" + props.month)}
                disabledDate={(date) => {
                    return !isValidDate(date)
                }}
                onSelect={(date) => {
                    setSelectedDate(date)
                }}
            />
            </div>

            {
                ( selectedDate && tideData.has(selectedDate.date()) ) &&

                    <Modal
                        width={'80%'}
                        open={selectedDate && tideData.has(selectedDate.date())}
                        okText={'Print'}
                        cancelText={'Close'}
                        onOk={()=> {
                            handlePrint(null, ()=>dailyRef.current)

                        }}
                        onCancel={()=>setSelectedDate(undefined)}
                        onClose={()=>setSelectedDate(undefined)}
                        styles={ {content : { height: '80%' } } }
                    >
                        <div style={{width: '100%', height: 1000}} ref={dailyRef}>
                            <TideLineDetail tideData={tideData.get(selectedDate.date())!!} dateInfo={selectedDate} obsId={props.obsId}/>
                        </div>
                    </Modal>

            }


        </>
    )
}