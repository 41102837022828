export class Observatory{

    constructor(
        public id : string,
        public name: string, latitude: number, longtitude:number) {

    }



}